import React, { useEffect, useContext } from 'react';

import TransitionLink from 'gatsby-plugin-transition-link';
import Feedback from '../components/feedback';
import Marquee from '../components/marquee';
import Authors from '../components/authors';
import Author from '../components/author';
import Button from '../components/button';
import Pager from '../components/pager';
import Page from '../components/page';
import SEO from '../components/seo';

import { PageWrapperContext } from '../components/page-wrapper';
import { transition, mdxComponents } from '../components/config';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { useData } from '../components/data';

import styles from '../styles/pages/article.module.scss';

export default function Template( { pageContext, transitionStatus } ) {

    const pageWrapperContext = useContext( PageWrapperContext );
    const { getAuthorsforIds } = useData();

    const { node, chapter } = pageContext;
    const { frontmatter, body } = node;
    const { title, shortTitle, description } = frontmatter;

    const authors = frontmatter.authors ? getAuthorsforIds( frontmatter.authors ) : [];

    useEffect( () => {
        pageWrapperContext.setPageClass( `page-article page-${chapter.frontmatter.id}` );
    }, [] );

    const renderAuthorsString = () => {
        let authorsString = '';
        authors.map( ( author, index ) => {
            authorsString += author.frontmatter.name;
            if ( authors.length > 1 ) {
                if ( index === authors.length - 1 ) {
                    authorsString += '.';
                } else if ( index === authors.length - 2 ) {
                    authorsString += ' and ';
                } else {
                    authorsString += ', ';
                }
            } else {
                authorsString += '.';
            }
        } );
        return authorsString;
    };

    const renderAuthors = () => {
        return authors.map( ( author, index ) => {
            return <Author
                key={index}
                frontmatter={author.frontmatter}
            />;
        } );
    };

    return <Page transitionStatus={transitionStatus}>
        <SEO title={title} description={description} image={`/images/opengraph/${chapter.frontmatter.id}.jpg`} />
        <section className="container-fluid">
            <div className={`row ${styles.title}`}>
                <div className="col-9 col-xs-12">
                    <h1>{shortTitle}</h1>
                    {authors.length > 0 &&
                        <div className={`${styles.meta}`}>
                            <p><small>By {renderAuthorsString()}</small></p>
                        </div>
                    }
                </div>
                <div className="col-2 col-xs-12 col-align-left">
                    <TransitionLink to={chapter.frontmatter.slug} {...transition}>
                        <Button reversed>Back</Button>
                    </TransitionLink>
                </div>
            </div>
        </section>
        <section className="container-fluid">
            <div className="row">
                <div className="col col-9 col-xs-12">
                    <h3 dangerouslySetInnerHTML={{ __html: description }} />
                </div>
            </div>
        </section>
        <section className={`${styles.body}`}>
            <div className="container-fluid">
                <MDXProvider components={mdxComponents}><MDXRenderer>{body}</MDXRenderer></MDXProvider>
            </div>
        </section>
        <Pager article={node} chapter={chapter} />
        <section className={`${styles.authorsMarquee}`}>
            <Marquee speed="4s"><h3>About The {authors && authors.length > 1 ? 'Authors' : 'Author'}</h3></Marquee>
        </section>
        <Authors>
            {renderAuthors()}
        </Authors>
        <Feedback />
    </Page>;
}
