import React from 'react';

import TransitionLink from 'gatsby-plugin-transition-link';

import { transition } from '../components/config';
import { useData } from '../components/data';

import '../styles/components/pager.scss';

const Pager = ( { article, chapter } ) => {

    const { getNextArticle, getPreviousArticle, getArticlePosition } = useData();

    const previous = getPreviousArticle( article );
    const next = getNextArticle( article );
    const index = getArticlePosition( article, chapter );
        
    return <section className="pager container-fluid">
        <div className="row">
            <div className="col-5 col-xs-12 previous">
                <TransitionLink to={previous.frontmatter.slug} {...transition}>
                    <h2>Previous</h2>
                    <h3>{previous.frontmatter.title}</h3>
                </TransitionLink>
            </div>
            <div className="col-2 col-xs-12 first-xs index">
                <h2>{index}</h2>
            </div>
            <div className="col-5 col-xs-12 next">
                <TransitionLink to={next.frontmatter.slug} {...transition}>
                    <h2>Next</h2>
                    <h3>{next.frontmatter.title}</h3>
                </TransitionLink>
            </div>
        </div>
    </section>;
};

export default Pager;
